import Swiper from 'swiper/bundle';

window.Swiper = Swiper;

window.TestimonialSlider = (randomId) => {
    return {
        swiper: null,
        init() {
            setTimeout(() => {
                this.swiper = new Swiper('.swiper-container'+randomId, {
                    loop: true,
                    spaceBetween: 100,
                    slidesPerView: 1,
                    speed: 800,
                    autoplay: {
                        delay: 7000,
                    },
                    navigation: {
                        nextEl: this.$refs.swiperNext,
                        prevEl: this.$refs.swiperPrev,
                    },
					breakpoints: {
						1024: {
							slidesPerView: 2
						},
					},
                });
            }, 0);
        },
    };
};
